
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class XrMyArticles extends Vue {
        // Data function
        public data() {
            return {
                headsetIconPathAndName:
                    require("@/assets/images/icon_headset.png"),
                nrealGlassesImagePathAndName:
                    require("@/assets/images/nreal_glasses.png"),
                oculusQuestImagePathAndName:
                    require("@/assets/images/oculus_quest_2.png"),
                valveIndexImagePathAndName:
                    require("@/assets/images/valve_index.png"),
                varjoXr1ImagePathAndName:
                    require("@/assets/images/varjo_xr_1.png"),
                viveProEyeImagePathAndName:
                    require("@/assets/images/vive_pro_eye.png"),
            };
        }
    }
